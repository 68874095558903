import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Rating from '@mui/material/Rating';
import Typography from '@mui/material/Typography';
import PriceValueGreen from '../assets/DlpAttributes/DlpAttPriceValueGreen.svg';
import PriceValueYellow from '../assets/DlpAttributes/DlpAttPriceValueYellow.svg';
import PriceValueRed from '../assets/DlpAttributes/DlpAttPriceValueRed.svg';
import i18n from '../../../utils/i18n';

const LineIcon = styled('span', {
    shouldForwardProp: (prop) => prop !== 'filled' && prop !== 'value',
})(({ filled, value }) => {
    let backgroundColor;
    if (value === 1) {
        backgroundColor = filled ? '#FB306D' : '#FECBDA';
    } else if (value >= 2 && value <= 3) {
        backgroundColor = filled ? '#FDB400' : '#FEECBF';
    } else if (value >= 4 && value <= 5) {
        backgroundColor = filled ? '#14BF96' : '#C4EFE4';
    }

    return {
        width: '3.5vw',
        minWidth: '30px',
        maxWidth: '55px',
        height: '4px',
        backgroundColor,
        borderRadius: '3px',
        '@media (max-width: 600px)': {
            width: '15vw',
            minWidth: '30px',
            maxWidth: '90px',
        },
    };
});

const RatingBarContainer = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    width: '100%',
});

const RatingStyled = styled(Rating)({
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '8px',
});

const RatingBar = ({ value }) => {
    let RatingBarIcon;
    if (value === 1) {
        RatingBarIcon = PriceValueRed;
    } else if (value >= 2 && value <= 3) {
        RatingBarIcon = PriceValueYellow;
    } else {
        RatingBarIcon = PriceValueGreen;
    }

    return (
        <RatingBarContainer>
            <img src={RatingBarIcon} alt="PriceValue Icon" style={{ width: '30px' }} />
            <Typography sx={{ fontSize: '0.9rem', fontWeight: 600, marginLeft: '8px' }}>
                {i18n.t('dlpproviderprofile.ratingpricevalue')}
            </Typography>
        </RatingBarContainer>
    );
};

const DlpAttPricevalue = ({ inputValue }) => {
    const [value, setValue] = useState(Math.round(inputValue));

    useEffect(() => {
        setValue(Math.round(inputValue));
    }, [inputValue]);

    return (
        <Box sx={{ padding: "8px 0px", width: '100%' }}>
            <RatingBar value={value} />
            <RatingStyled
                name="customized-color"
                value={value}
                getLabelText={(value) => `${value} Line${value !== 1 ? 's' : ''}`}
                precision={1}
                icon={<LineIcon filled={true} value={value} />}
                emptyIcon={<LineIcon filled={false} value={value} />}
                readOnly
            />
        </Box>
    );
};

export default DlpAttPricevalue;
