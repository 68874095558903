import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Navigation from '../components/navigation/navigation';
import TextField from '@mui/material/TextField';
import ACTIONS from '../../../redux/action';
import i18n from '../../../utils/i18n';
import { withTranslation } from 'react-i18next';

const Industry = ({ ...props }) => {
	const companyStore = useSelector((state) => state.company_store);

	const dispatch = useDispatch();

	const handleNext = useCallback(
		(inc) => {
			props.handleNext(inc);
		},
		[props]
	);

	const handleBack = useCallback(
		(decr) => {
			props.handleBack(decr);
		},
		[props]
	);

	const foundingpriceslist = {
		singleidk: 12900,
		singleprimary: 12900,
		singlesecondary: 12900,
		kiva: 12900,
		vszja: 12900,
		bt: 29900,
		kft: 79900,
	};

	const formatPrice = (price) => {
		return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
	};

	const getPrice = () => {
		return formatPrice(foundingpriceslist[companyStore.companytype]);
	};

	return (
		<div className="stepContainer">
			<div>
				<div className="highlightedTextArea">
					<div style={{ fontSize: '16px', fontWeight: '700', margin: '6px 0' }}>
						{i18n.t('searchflow.reglabelmessage1')}
					</div>
					<p
						style={{ fontSize: '16px', fontWeight: '300', lineHeight: '24px' }}>
						{i18n.t('searchflow.reglabelmessage2')}
					</p>
					<p style={{ fontSize: '16px', fontWeight: '700' }}>
						{i18n.t('general.price')}: {getPrice()} {i18n.t('general.currency')}
					</p>
					<p
						style={{
							fontSize: '16px',
							fontWeight: '300',
							fontStyle: 'italic',
							lineHeight: '24px',
						}}>
						{i18n.t('searchflow.reglabelmessage3')}
					</p>
				</div>
				<hr />

				<p className="SFSubTitle">
					<span className="SFSubTitle">
						{i18n.t('searchflow.vafmessagelabel')} -{' '}
						{i18n.t('general.obligartory')}
					</span>{' '}
				</p>
				<div className="SFInput">
					<TextField
						placeholder={i18n.t('searchflow.regindustryreqmessageph')}
						multiline
						maxRows={4}
						minRows={2}
						variant="outlined"
						value={companyStore.reqMessage}
						onChange={(event) => {
							dispatch(
								ACTIONS.addCompanyInfo({
									id: event.target.id,
									data: event.target.value,
									stateObject: 'company_store',
								})
							);
						}}
						id="reqMessage"
						name="reqMessage"
					/>
				</div>
			</div>
			<Navigation
				addCompanyInfo={props.addCompanyInfo}
				step={1}
				handleNext={() => handleNext()}
				handleBack={() => handleBack()}
				openHelpForm={() => props.openHelpForm()}
				disabledProp={companyStore.reqMessage !== '' ? false : true}
			/>
		</div>
	);
};

export default withTranslation()(Industry);
