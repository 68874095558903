import React, { useEffect, useState } from 'react';
import Rating from '@mui/material/Rating';
import FullyOnline from '../../../assets/DlpFullyOnline.svg';
import DigitalAccountant from '../../../assets/DlpDigitalAccountant.svg';
import DlpMUIProfileImageDefault from '../../../assets/DlpMUIProfileImageDefault.svg';
import buildings from '../../../../../images/icons/buildings.svg';
import StyledButton from '../../../../../shared/components/styledButton/styledButton';
import i18n from '../../../../../utils/i18n';
import { ApprovedProviderBadge } from './ApprovedProviderBadge';
import { ProviderExperienceCards } from './ProviderExperienceCards';
import axios from '../../../../utils/axiosCompanyProxy';

const maxProviderNameLength = 50;

const CardContentWeb = ({ data, setOpenForward, ...props }) => {
	const [chosenProviderCount, setChosenProviderCount] = useState(null);
	const accountLifetime = calculateAccountLifetime(data.startDate);

	function calculateAccountLifetime(dateTime) {
		const inputDate = new Date(dateTime);
		const currentDate = new Date();

		if (isNaN(inputDate)) {
			console.error("Invalid date format, can't determine account lifetime!");
			return {
				years: 0,
				months: 0
			};
		}

		let startDate = inputDate > currentDate ? currentDate : inputDate;
		let endDate = inputDate > currentDate ? inputDate : currentDate;

		let yearsDiff = endDate.getFullYear() - startDate.getFullYear();
		let monthsDiff = endDate.getMonth() - startDate.getMonth();

		if (monthsDiff < 0) {
			yearsDiff -= 1;
			monthsDiff += 12;
		}

		// console.log('DIFF', yearsDiff, monthsDiff);

		return {
			years: yearsDiff,
			months: monthsDiff
		}
	}

	function isNewAccount() {
		return accountLifetime.years === 0 && accountLifetime.months === 0;
	}

	function formatAccountLifetimeString() {
		if (isNewAccount()) return;

		if (accountLifetime.years < 1) {
			return `${accountLifetime.months} ${i18n.t('dlpproviderprofile.formonths')}`;
		} else {
			return `${accountLifetime.years} ${i18n.t('dlpproviderprofile.foryears')}`;
		}
	}

	function maybeTruncateProviderName() {
		const maxLength = maxProviderNameLength;

		if (data.providerName.length > maxLength) {
      // subtracking 3 for the dots
			return data.providerName.substring(0, maxLength - 3).concat("...");
		}
		return data.providerName;
	}

	const responseTime = {
		veryfast: i18n.t('general.rptimeveryfast'),
		fast: i18n.t('general.rptimefast'),
		decent: i18n.t('general.rptimedecent'),
	};

	useEffect(() => {
		const fetchChosenProviderCount = async () => {
			try {
				const providerName = data.providerName;
				const response = await axios.get(
					`/api/aspreq/getchosenprovidercount/${encodeURIComponent(
						providerName
					)}`
				);
				if (response.data) {
					setChosenProviderCount(response.data.chosenProviderCount);
				} else {
					throw new Error('Invalid response data!');
				}
			} catch (error) {
				console.error('Error fetching chosenprovider count:', error);
			}
		};
		fetchChosenProviderCount();
	}, [data.providerName]);

	return (
		<>
			<div className="dlpProviderCardSection">
				<div className="dlpProviderCardHeader">
					<img
						src={data.providerImage || DlpMUIProfileImageDefault}
						alt="profile"
						style={{
							width: '48px',
							height: '48px',
							position: 'relative',
							objectFit: 'cover',
							objectPosition: 'top',
							borderRadius: '50%',
						}}
					/>
					<div className="dlpProviderCardTextContainer">
						<span className="dlpBold16" title={data.providerName}>
							{maybeTruncateProviderName()}
						</span>
						<p className='dlpProviderCardRating' style={{ margin: '0px', fontSize: '12px' }}>
							<Rating
								name="half-rating-read"
								value={data.reviewAvg}
								precision={0.1}
								readOnly
								size="small"
								sx={{ marginRight: '8px' }}
							/>
							{data.reviewNr > 0 && (
								<>
									{data.reviewAvg} ({data.reviewNr})
								</>
							)}
						</p>
						<div className="dlpTextProvSize">
							<img src={buildings} alt="buildings" />{' '}
							{data.packageSize === ''
								? i18n.t('general.accountant')
								: data.packageSize === 'entrepreneur'

									? i18n.t('provider.entrepreneuracc')
									: data.packageSize === 'small_office'
										? i18n.t('provider.small_acc_office')
										: i18n.t('provider.large_acc_office')}
						</div>
						< ApprovedProviderBadge
							data={data}
							accountLifeTime={accountLifetime}
							chosenProviderCount={chosenProviderCount}
						/>
					</div>
				</div>
			</div>
			<ProviderExperienceCards
				providerExperience={data.providerExperience}
				maxNumberOfCards={4}
			/>

			<hr style={{ margin: '6px 0px !important' }} />
			<div className="dlpProviderCardBottom">
				<div className="dlpProviderCardBottomSub">
					{!isNewAccount() &&
						(<>
							<img src={FullyOnline} alt="fully online" className="fullyOnline" />{' '}
							<span className="dlpText12">{formatAccountLifetimeString()}
								{' '}{i18n.t('general.bkaccountant')}
							</span>

						</>)
					}
					{data.providerBadge !== "Nem minősített"
						&& data.providerBadge !== "Bronz minősítés"
						&& (
							<>
								<br />
								<img
									src={DigitalAccountant}
									alt="digital accountant"
									className="digitalAccountant"
								/>{' '}
								<span className="dlpText12">
									{i18n.t('general.digitalaccountant')}
								</span>
							</>
						)}

					<div>
						{['veryfast', 'fast', 'decent'].includes(
							data.providerResponseTime
						) && (
							<span className="dlpText12">
								<img
									src={FullyOnline}
									alt="fully online"
									className="fullyOnline"
								/>{' '}
								{i18n.t('general.responsetime')}:{' '}
								{responseTime[data.providerResponseTime]}
							</span>
						)}
					</div>
				</div>

				{data.packageType === 'premium' && (
					<StyledButton
						onClick={(e) => {
							setOpenForward(true);
							e.stopPropagation();
						}}
						color={'NewReqBtn'}>
						{i18n.t('providerdashboard.requestcapital')}
					</StyledButton>
				)}
			</div>
		</>
	);
};

export default CardContentWeb;
