import i18n from '../../../../../utils/i18n';
import ApprovedProvCard from '../../../assets/DlpApprovedProvCard.svg';

export function ApprovedProviderBadge({
	data,
	accountLifeTime,
	chosenProviderCount,
}) {
	let badgeText;
	switch (true) {
		case chosenProviderCount >= 10 && data.reviewNr >= 5 && data.reviewAvg >= 4:
			badgeText = i18n.t('dlpproviderprofile.highreliability');
			break;
		case chosenProviderCount >= 10 && data.reviewNr >= 8 && data.reviewAvg >= 4:
			badgeText = i18n.t('dlpproviderprofile.maximumreliability');
			break;
		case accountLifeTime.years >= 2:
			badgeText = i18n.t('dlpproviderprofile.highreliability');
			break;
		case chosenProviderCount >= 6 && chosenProviderCount < 10:
			badgeText = i18n.t('dlpproviderprofile.stable');
			break;
		case accountLifeTime.years === 1:
			badgeText = i18n.t('dlpproviderprofile.stable');
			break;
		case chosenProviderCount === 0:
			badgeText = i18n.t('dlpproviderprofile.newpartner');
			break;
		case chosenProviderCount >= 3 && chosenProviderCount < 6:
			badgeText = i18n.t('dlpproviderprofile.promisingstart');
			break;
		case accountLifeTime.years === 0 && accountLifeTime.months < 12:
			badgeText = i18n.t('dlpproviderprofile.newpartner');
			break;
		default:
			badgeText = i18n.t('general.certifiedaccountantshort');
			break;
	}
  
	return (
		data.packageType === 'premium' && (
			<div className="dlpApprovedProv">
				<img
					className="dlpApprovedProvCardImg"
					alt="approved prov card"
					src={ApprovedProvCard}
				/>
				{/* <span className='dlpApprovedText'>{i18n.t('general.certifiedaccountantshort')}</span>{' '} */}
				<span className="dlpApprovedText">{badgeText}</span>
			</div>
		)
	);
}
