import React from 'react';
import './dynamicListPagesFooter.scss';
import BkLogoWhite from '../assets/BkLogoWhite.svg';
import LinkedInIcon from '../assets/DlpFooter/DlpFooterLinkedInIcon.svg';
import MediumIcon from '../assets/DlpFooter/DlpFooterMediumIcon.svg';
import FacebookIcon from '../assets/DlpFooter/DlpFooterFacebookIcon.svg';
import InstagramIcon from '../assets/DlpFooter/DlpFooterInstagramIcon.svg';
import EnvelopeIcon from '../assets/DlpFooter/DlpFooterEnvelopeIcon.svg';
import MobileIcon from '../assets/DlpFooter/DlpFooterMobileIcon.svg';
import MapIcon from '../assets/DlpFooter/DlpFooterMapIcon.svg';

const DynamicListPagesFooter = () => {
    return (
        <div className='dynamicListPagesFooter'>
            <div className='dlpFooterGrid'>
                <div className='dlpFooterLogoContainer'>
                    <img src={BkLogoWhite} alt="Bk Logo White" className='dlpFooterBkLogoWhite' />
                    <p>A kereső oldal ahol a könyvelők és ügyfeleik találkoznak</p>
                    <div className='dlpFooterSocialIcons'>
                        <a href="https://hu.linkedin.com/company/bookkeepie-hu">
                            <img src={LinkedInIcon} alt="LinkedIn" />
                        </a>
                        <a href="https://www.tiktok.com/@bookkeepie_hu">
                            <img src={MediumIcon} alt="Medium" />
                        </a>
                        <a href="https://www.facebook.com/BookkeepieHU/">
                            <img src={FacebookIcon} alt="Facebook" />
                        </a>
                        <a href="https://www.instagram.com/bookkeepie_hu/">
                            <img src={InstagramIcon} alt="Instagram" />
                        </a>
                    </div>
                </div>
                <div className='dlpFooterLinksContainer'>
                    <div className='dlpFooterLinksSection'>
                        <p><a href="#">Bemutatkozás</a></p>
                        <p><a href="#">Könyvelőt keresőknek</a></p>
                        <p><a href="#">Könyvelőknek</a></p>
                    </div>
                    <div className='dlpFooterLinksSection'>
                        <p><a href="#">Blog</a></p>
                        <p><a href="#">Sajtómegjelenéseink</a></p>
                        <p><a href="#">Kapcsolat</a></p>
                    </div>
                </div>
                <div className='dlpFooterContactsSection'>
                    <div className='dlpFooterContactsSectionIcons'>
                        <img src={EnvelopeIcon} alt="Envelope Icon" />
                        <p><a href="mailto:info@konyvelo-net.hu">info@konyvelo-net.hu</a></p>
                    </div>
                    <div className='dlpFooterContactsSectionIcons'>
                        <img src={MobileIcon} alt="Mobile Icon" />
                        <p><a href="tel:+36301234567">+36 30 123 4567</a></p>
                    </div>
                    <div className='dlpFooterContactsSectionIcons'>
                        <img src={MapIcon} alt="Map Icon" />
                        <p><a href="#">1024 Budapest, Knet utca 21</a></p>
                    </div>
                </div>
            </div>
            <div className='dlpFooterBottom'>
                <p>© 2024 Könyvelő-Net. Minden jog fenntartva.</p>
                <p>
                    <a href="#">Adatvédelmi Tájékoztató</a>
                    <a href="#">ÁSZF</a>
                    <a href="#">SimplePay Fizetési Tájékoztató</a>
                </p>
            </div>
        </div>
    );
};

export default DynamicListPagesFooter;
